'use client'

import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { OnboardingModelContent } from './modal-inner-content'

export const OnboardingModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'full', sm: '' }}>
        <ModalOverlay />
        <ModalContent
          w={{ base: '100dvw', sm: '70dvw', md: '70dvw' }}
          maxW={{ base: '100%', sm: '800px' }}
          h={['100dvh', '100dvh', '80dvh', '72dvh', '63dvh']}
          zIndex={1000}
        >
          <ModalBody h="100%" px={0}>
            <OnboardingModelContent closeHandler={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
