'use client'
import { excludedPages } from '@/components/ui/sidebar'
import { cn } from '@/lib/utils'
import Image from 'next/image'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'

export default function LoadingScreen() {
  const [loading, setLoading] = useState(true)
  const pathname = usePathname()
  useEffect(() => {
    setLoading(false)
  }, [])

  if (!loading) return null

  return (
    <section
      className={cn(
        'fixed inset-0 z-[1002] flex h-full w-full items-center justify-center bg-white',
        excludedPages.includes(pathname||"") ? '' : 'xxl:hidden'
      )}
    >
      <Image
        src={`/lykstage-logo.svg`}
        alt="Lykstage"
        className="h-auto"
        width={200}
        height={48.8}
      />
    </section>
  )
}
