'use client'
import { useUser } from '@/src/store/user-store'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect } from 'react'

const ignoredRoutes = [
  '/community',
  '/contact-us',
  '/data-usage',
  '/faqs',
  '/how-earnings-work',
  '/privacypolicy',
  '/termsofservice',
]

const notAllowedRoutesLoggedOut = ['/account']

export const AuthenticationCheck = () => {
  const { user, _hasHydrated } = useUser()
  const pathname = usePathname()
  const router = useRouter()
  useEffect(() => {
    if (user !== null && user.verifiedUser == '0' && _hasHydrated) {
      router.replace('/auth/verify')
    } else if (
      user !== null &&
      user.isOnboarded == '0' &&
      _hasHydrated &&
      !ignoredRoutes.includes(pathname||"")
    ) {
      router.replace('/auth/verify/onboarding')
    } else if (
      user === null &&
      notAllowedRoutesLoggedOut.includes(pathname||"") &&
      _hasHydrated
    ) {
      router.replace('/home')
    }
  }, [user, router, _hasHydrated, pathname])

  return <></>
}
