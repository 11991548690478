'use client'

import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Box,
  ModalFooter,
  Flex,
  Icon,
  Switch,
  Image,
  Link,
} from '@chakra-ui/react'
import { FaChevronLeft } from 'react-icons/fa'
import { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6'
import { useCookie } from '@/src/store/cookie-store'

export const Cookie1 = ({
  isOpen,
  onClose,
  handleNext,
}: {
  isOpen: boolean
  onClose: () => void
  handleNext: () => void
}) => {
  const { setIsAccepted, setSelectedOption } = useCookie()

  function handleSubmit(value: 'All' | 'Only Necessary') {
    setIsAccepted(true)
    setSelectedOption(value)
    onClose()
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={['sm', 'sm', '2xl']}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text opacity={0.7}>Accept Cookies</Text>
            {/* <ModalCloseButton m="1" /> */}
          </ModalHeader>
          <ModalBody className="overflow-hidden">
            <Box>
              <Text fontSize={['xl', 'xl', '2xl']} fontWeight={'600'}>
                We’d love your input to enhance your experience on our platform!
                🍪🍪
              </Text>
              <Text fontSize={'sm'} mt="4">
                We employ cookies to ensure website functionality, analyze our
                website traffic, and enable social media features. You can learn
                more about the various cookies we utilize in our Cookie
                Settings. For additional details and instructions on adjusting
                your cookie preferences, please refer to our{' '}
                <Link
                  color="lyk-black"
                  fontSize={12}
                  fontWeight={600}
                  href="/privacypolicy"
                  className="noBorder"
                >
                  Privacy Policy.
                </Link>
              </Text>
              <Text fontSize={'sm'} fontWeight={'600'} mt="4" mb={4}>
                By clicking ‘Accept All Cookies’, you agree to our{' '}
                <Link
                  href="/privacypolicy"
                  className="underline"
                  target="_blank"
                >
                  <Text as="span" color="lyk-green">
                    Privacy Policy
                  </Text>
                </Link>
                 and 
                <Link href="/data-usage" className="underline" target="_blank">
                  <Text as="span" color="lyk-green">
                    Data Usage Policy
                  </Text>
                </Link>
              </Text>
            </Box>
          </ModalBody>
          <hr />
          <ModalFooter>
            <Flex
              gap={[2, 2, 4]}
              w="100%"
              justifyContent={'space-between'}
              direction={['column', 'column', 'row', 'row']}
              alignItems="center"
            >
              <Button
                _hover={{ opacity: 0.7 }}
                fontWeight={500}
                variant={'destructive'}
                onClick={handleNext}
                mb={2}
                px={0}
              >
                Cookie Settings
              </Button>
              <Flex gap="4" flexWrap="wrap">
                <Button
                  px={8}
                  py={6}
                  _hover={{ opacity: 0.7 }}
                  size="sm"
                  fontWeight={500}
                  onClick={() => handleSubmit('Only Necessary')}
                  bg={'#ECECEC'}
                  flex={1}
                  minW="170px"
                  fontSize={['sm', 'sm', 'md']}
                >
                  Accept only necessary
                </Button>
                <Button
                  px={8}
                  py={6}
                  flex={1}
                  minW="170px"
                  _hover={{ opacity: 0.7 }}
                  size="sm"
                  fontWeight={500}
                  bg={'lyk-green'}
                  color={'white'}
                  fontSize={['sm', 'sm', 'md']}
                  onClick={() => handleSubmit('All')}
                >
                  Accept all cookies
                </Button>
              </Flex>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export const Cookie2 = ({
  isOpen,
  onClose,
  handleNext,
}: {
  isOpen: boolean
  onClose: () => void
  handleNext: () => void
}) => {
  const [show, setShow] = useState(false)
  const [show2, setShow2] = useState(false)
  const [show3, setShow3] = useState(false)

  const [option1, setOption1] = useState(false)
  const [option2, setOption2] = useState(false)

  const { setIsAccepted, setSelectedOption } = useCookie()

  function handleChange() {
    setIsAccepted(true)
    if (option1 && option2) {
      setSelectedOption('All')
    } else if (option1) {
      setSelectedOption('Option 1')
    } else if (option2) {
      setSelectedOption('Option 2')
    } else {
      setSelectedOption('Only Necessary')
    }
    onClose()
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: 'full', sm: '3xl' }}
      >
        <ModalOverlay />
        <ModalContent maxH={['100vh', '100vh', 'none']}>
          <ModalHeader>
            <Button
              variant="ghost"
              leftIcon={<Icon as={FaChevronLeft} />}
              fontWeight="400"
              px="0"
              onClick={handleNext}
            >
              Go back
            </Button>
          </ModalHeader>
          <ModalBody
            pt={0}
            maxH={['100%', '100%', '430px']}
            overflow="scroll"
            className="scrollbar-thin"
          >
            <Box pb={10}>
              <Text fontSize={'xl'} opacity={0.8} fontWeight={'600'}>
                Privacy Preference Center
              </Text>
              <Text fontSize={['sm', 'sm', 'md']} mt="4">
                When you browse any website, it may collect or retrieve data
                from your web browser, primarily in the form of cookies. This
                data could pertain to you, your preferences, or your device and
                is typically employed to ensure the website functions as you
                anticipate. This data generally does not directly identify you,
                but it can enhance your web experience by making it more
                personalized. We <b>highly value your privacy rights</b>, which
                is why you have the option to opt out of certain cookie types.
              </Text>
              <Text fontSize={['sm', 'sm', 'md']} mt="4">
                Click on the various category headings to learn more and modify
                our default settings. However, please be aware that disabling
                some cookie types may affect your experience on the site and the
                range of services we can provide.
              </Text>

              <Box
                position={'relative'}
                bg={'lyk-gray'}
                rounded="md"
                onClick={() => setShow((prev) => !prev)}
              >
                <Flex
                  bg={'lyk-green'}
                  rounded="md"
                  mt="4"
                  px="4"
                  py="2"
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Flex alignItems="center" flex={1}>
                    <Box
                      fontWeight={'600'}
                      pr="1"
                      maxW={['140', '140', 'none']}
                      fontSize={['sm', 'sm', 'md']}
                      margin={'0'}
                      color="white"
                    >
                      Strictly Necessary Cookies
                    </Box>
                    <Image
                      boxSize={4}
                      src="/account/info-white.svg"
                      alt="Info"
                    />
                  </Flex>

                  <Button
                    rightIcon={<Icon as={show ? FaChevronUp : FaChevronDown} />}
                    fontWeight="400"
                    px="0"
                    bg={'transparent'}
                    color="white"
                    variant={'destructive'}
                    fontSize={['sm', 'sm', 'md']}
                  >
                    Always active
                  </Button>
                </Flex>
                {show && (
                  <Text p="4" fontSize={'sm'}>
                    These cookies are essential for the website to operate and
                    cannot be deactivated in our systems. These cookies are
                    required to enable core site functionality. They are
                    typically established in response to actions you take that
                    constitute a request for services, like adjusting your
                    privacy preferences, logging in, or completing forms.
                    Additionally, we may utilize these cookies to establish
                    essential functions that ensure the security and efficiency
                    of the requested service, including authentication and load
                    balancer requests. You have the option to configure your
                    browser to block or notify you about these cookies, but this
                    may result in certain parts of the site becoming
                    non-functional.
                  </Text>
                )}
              </Box>
              <Box
                position={'relative'}
                bg={'lyk-gray'}
                rounded="md"
                onClick={() => setShow2((prev) => !prev)}
              >
                <Flex
                  rounded="md"
                  mt="4"
                  px="4"
                  py="2"
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Flex alignItems="center">
                    <Box
                      fontWeight={'600'}
                      pr="1"
                      maxW={['140', '140', 'none']}
                      fontSize={['sm', 'sm', 'md']}
                      margin={'0'}
                    >
                      Targeting Cookies (Optional)
                    </Box>
                    <Image boxSize={4} src="/account/info.svg" alt="Info" />
                  </Flex>
                  <Flex
                    flex={'1'}
                    justifyContent={'flex-end'}
                    alignItems={'center'}
                  >
                    <Switch
                      sx={{
                        '.chakra-switch__track[data-checked]:not([data-theme])':
                          { backgroundColor: 'lyk-green' },
                      }}
                      onChange={() => {
                        setOption1((prev) => !prev)
                      }}
                      size={['sm', 'sm', 'md']}
                    />
                    <Button
                      rightIcon={
                        <Icon as={show2 ? FaChevronUp : FaChevronDown} />
                      }
                      fontWeight="400"
                      px="0"
                      bg={'transparent'}
                      variant={'destructive'}
                    />
                  </Flex>
                </Flex>
                {show2 && (
                  <Text p="4" pt="0" fontSize={'sm'}>
                    These cookies can be placed on our website by our
                    advertising partners. They may be employed by these
                    companies to create a profile of your interests and display
                    relevant advertisements on other websites. They do not store
                    personal information directly but rely on identifying your
                    browser and internet device uniquely. If you opt not to
                    permit these cookies, you may encounter less personalized
                    advertising.
                  </Text>
                )}
              </Box>
              <Box
                position={'relative'}
                bg={'lyk-gray'}
                rounded="md"
                onClick={() => setShow3((prev) => !prev)}
              >
                <Flex
                  rounded="md"
                  mt="4"
                  px="4"
                  py="2"
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Flex alignItems="center">
                    <Box
                      fontWeight={'600'}
                      pr="1"
                      maxW={['140', '140', 'none']}
                      fontSize={['sm', 'sm', 'md']}
                      margin={'0'}
                    >
                      Social Media Cookies (Optional)
                    </Box>
                    <Image boxSize={4} src="/account/info.svg" alt="Info" />
                  </Flex>
                  <Box>
                    <Switch
                      sx={{
                        '.chakra-switch__track[data-checked]:not([data-theme])':
                          { backgroundColor: 'lyk-green' },
                      }}
                      onChange={() => {
                        setOption2((prev) => !prev)
                      }}
                      size={['sm', 'sm', 'md']}
                    />
                    <Button
                      rightIcon={
                        <Icon as={show3 ? FaChevronUp : FaChevronDown} />
                      }
                      fontWeight="400"
                      px="0"
                      bg={'transparent'}
                      variant={'destructive'}
                      fontSize={['sm', 'sm', 'md']}
                    />
                  </Box>
                </Flex>
                {show3 && (
                  <Text p="4" pt="0" fontSize={'sm'}>
                    These cookies are established by various social media
                    services integrated into our website, enabling you to easily
                    share our content with your friends and networks. They have
                    the ability to track your browsing activity on other sites
                    and create a profile based on your interests. This can
                    influence the content and advertisements you encounter on
                    other websites you visit. If you choose to block these
                    cookies, you may not have access to or see the sharing
                    tools.
                  </Text>
                )}
              </Box>
            </Box>
          </ModalBody>
          <hr />
          <ModalFooter>
            <Flex
              gap="4"
              w="100%"
              justifyContent={'flex-end'}
              alignItems={'center'}
            >
              <Button
                px={10}
                type="submit"
                form="change-password-form"
                _hover={{ opacity: 0.7 }}
                size={['sm', 'sm', 'md']}
                fontWeight={500}
                bg={'lyk-green'}
                color={'white'}
                onClick={handleChange}
              >
                Proceed
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
