'use client'
import { excludedPages } from '@/components/ui/sidebar'
import { NAVBAR_HEIGHT_PX } from '@/lib/constans'
import { cn } from '@/lib/utils'
import { useSidebar } from '@/src/store/sidebar-store'
import { usePathname } from 'next/navigation'

export default function SidebarWrapper({
  children,
}: {
  children: React.ReactNode
}) {
  const { isSideBarOpen } = useSidebar()
  const pathname = usePathname()
  return (
    <>
      {
        pathname === '/home' ? <div className='homeBackground' /> : ""
      }
      <main
        className={cn(
          'ease ml-auto flex w-full flex-1 transition-all duration-500 ease-in-out',
          isSideBarOpen && !excludedPages.includes(pathname||"")
            ? `xxl:max-w-[calc(100%_-_var(--sidebar-width))]`
            : `xxl:max-w-[calc(100%)]`
        )}
        style={{
          marginTop: NAVBAR_HEIGHT_PX,
        }}
      >
        {children}
      </main>
    </>

  )
}
