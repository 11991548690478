'use client'
import { OnboardingModal } from '@/components/ui/onboarding/onboarding-modal'
import { useDisclosure } from '@chakra-ui/react'

export default function OnboardingModalWrapper() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <OnboardingModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}
