'use client'

import { useDisclosure } from '@chakra-ui/react'
import { Cookie1, Cookie2 } from '@/components/cookie/CookieModal'
import { useState, useEffect } from 'react'
import { useCookie } from '@/src/store/cookie-store'

export default function CookieModalWrapper() {
  const [currentPage, setCurrentPage] = useState(0)

  const { isOpen, onOpen, onClose } = useDisclosure()
  function handleNext() {
    if (currentPage === 0) {
      setCurrentPage((prev) => prev + 1)
    }
    if (currentPage === 1) {
      setCurrentPage((prev) => prev - 1)
    }
  }

  const { isAccepted, _hasHydrated } = useCookie()

  useEffect(() => {
    if (!isAccepted && _hasHydrated) {
      onOpen()
    }
  }, [isAccepted, onOpen, _hasHydrated])

  return (
    <>
      {currentPage == 0 && (
        <Cookie1 isOpen={isOpen} onClose={onClose} handleNext={handleNext} />
      )}
      {currentPage == 1 && (
        <Cookie2 isOpen={isOpen} onClose={onClose} handleNext={handleNext} />
      )}
    </>
  )
}
