import { createJSONStorage, persist } from 'zustand/middleware'
import { create } from 'zustand'

export interface CookieSlice {
  isAccepted: boolean
  setIsAccepted: (value: boolean) => void
  selectedOption: 'All' | 'Only Necessary' | 'Option 1' | 'Option 2' | null
  setSelectedOption: (
    value: 'All' | 'Only Necessary' | 'Option 1' | 'Option 2'
  ) => void
  _hasHydrated?: boolean
}

export const useCookie = create(
  persist<CookieSlice>(
    (set) => ({
      isAccepted: false,
      setIsAccepted: (value: boolean) => set({ isAccepted: value }),
      selectedOption: null,
      setSelectedOption: (
        value: 'All' | 'Only Necessary' | 'Option 1' | 'Option 2'
      ) => set({ selectedOption: value }),
    }),
    {
      name: 'cookie-storage-lykstage',
      storage: createJSONStorage(() => localStorage),
      onRehydrateStorage: () => (state) => {
        if (state) {
          state._hasHydrated = true
        }
      },
    }
  )
)
