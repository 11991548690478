/* eslint-disable @typescript-eslint/no-explicit-any */
'use client'

import { useState } from 'react'
import { VStack, Box, Flex, useBreakpointValue } from '@chakra-ui/react'
import { OnboardingModalHeader } from './onboarding-modal-header'
import { Interests } from '@/components/auth-page/interests-onboarding'
import { Languages } from '@/components/auth-page/languages-onboarding'
import { useRouter } from 'next/navigation'
import { toast } from 'sonner'
import { useOnboarding } from '@/src/store/onboarding-store'
import { onboardingData } from '@/data/auth/onboarding'
import { OnboardingModalFooterGuest } from './onboarding-modal-footer-guest'

export const OnboardingModelContent = ({
  closeHandler,
}: {
  closeHandler: () => void
}) => {
  const [currentStep, setCurrentStep] = useState(1)
  const router = useRouter()
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([])
  const [selectedInterests, setSelectedInterests] = useState<string[]>([])
  const { setLanguageIds, setInterestIds, setIsCompleted } = useOnboarding()
  const position = useBreakpointValue({ base: 'flex-start', lg: 'center' })
  const spacing = useBreakpointValue({ base: '5', sm: '4' })
  onboardingData[1].subdescription = undefined
  const handleNextStep = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()

    if (currentStep == 1) {
      if (selectedInterests.length < 3) {
        toast.error('Please select at least three interests', {
          duration: 1500,
        })
        return
      }
      setCurrentStep((prevStep) => prevStep + 1)
    }
    if (currentStep == 2) {
      if (selectedLanguages.length < 3) {
        toast.error('Please select at least three languages', {
          duration: 1500,
        })
        return
      }

      setInterestIds(selectedInterests.join(','))
      setLanguageIds(selectedLanguages.join(','))
      setIsCompleted(true)
      closeHandler()
    }
  }

  const handleSignup = () => {
    router.push('/auth/signup')
  }

  return (
    <Box className="mx-auto flex h-full w-full flex-col overflow-hidden">
      <Box className="sticky top-0 w-[100%] bg-white px-5 pt-6 md:px-10">
        <Box className="flex w-full justify-center">
          <Flex p={0} w={{ base: '100%', lg: '25%' }}>
            <Box
              h="1"
              flex="1"
              bg={currentStep >= 1 ? 'lyk-green' : 'lyk-textborder'}
              rounded={'full'}
            />
            <Box h="1" w="4" />
            <Box
              h="1"
              flex="1"
              bg={currentStep >= 2 ? 'lyk-green' : 'lyk-textborder'}
              rounded={'full'}
            />
          </Flex>
        </Box>
      </Box>

      <VStack
        mx={[0]}
        className="mx-auto flex flex-1 flex-col overflow-hidden px-5 md:px-8"
      >
        <OnboardingModalHeader
          subtitle={onboardingData[currentStep - 1].subtitle}
          subdescription={onboardingData[currentStep - 1].subdescription}
        />

        <div className="flex-1 overflow-y-scroll border-b pb-5 pt-2 scrollbar-none sm:pb-0">
          {currentStep === 1 && (
            <>
              <Interests
                selectedInterests={selectedInterests}
                setSelectedInterests={setSelectedInterests}
                position={position ?? 'center'}
                spacing={spacing ?? '5'}
              />
            </>
          )}
          {currentStep === 2 && (
            <Languages
              selectedLanguages={selectedLanguages}
              setSelectedLanguages={setSelectedLanguages}
              position={position ?? 'center'}
            />
          )}
        </div>
      </VStack>
      <OnboardingModalFooterGuest
        handleSignup={handleSignup}
        handleNextStep={handleNextStep}
      />
    </Box>
  )
}
